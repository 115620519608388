import { Link } from "@remix-run/react"

export default function Footer() {
  return (
    <footer className="my-5 flex flex-wrap justify-center gap-3 text-sm">
    <a
      className="opacity-70 hover:opacity-95"
      href="mailto:support@hydra-newmedia.com"
    >
      Contact Support
    </a>
    <Link
      className="opacity-70 hover:opacity-95"
      to="/legal/privacy-policy"
    >
      Privacy policy
    </Link>
    <Link className="opacity-70 hover:opacity-95" to="/legal/imprint">
      Imprint (German)
    </Link>
  </footer>
  )
}
